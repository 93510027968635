
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Payment List</h5>
      <div class="card">
        <div class="card-body">
          <div class="row mb-2 gy-2">
            <div class="col-sm-12 col-md-6 d-flex">
              <div id="tickets-table_length" class="me-2 dataTables_length">
                <label class="d-inline-flex mb-0 align-items-center">
                  <b-form-select class="form-select" v-model="paginate.perPage" :options="pageOptions"></b-form-select>
                </label>
              </div>
              <div class="dropdown no-arrow me-2">
                <a class="btn btn-light dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="ri ri-filter-3-fill"></i>
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <button @click="selectAllRows()" class="dropdown-item" type="button">
                       {{isSelectAll? 'Invert Selection' : 'Select all' }}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="form-inline text-sm-end">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex mb-0 align-items-center">
                    <b-form-input v-model="searchQuery" type="search" placeholder="Search email..." class="form-control"></b-form-input>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive mb-0">
            <b-table striped hover ref="selectableTable" :items="paginatedItems"
                :fields="columns" responsive="sm">
                  <template v-slot:cell(check)="data">
                    <div style="width:3rem;" class="form-check text-center ms-auto">
                      <input v-model="selected" type="checkbox" :value="data.item.id" class="form-check-input me-2" :id="`tableSRadio${data.item.id}`" />
                      <label class="form-check-label" :for="`tableSRadio${data.item.id}`">{{data.item.id}}</label>
                    </div>
                  </template>
                  <template v-slot:cell(paid_amount)="data">
                    <span> ${{data.item.paid_amount}} </span>
                  </template>
                  <template v-slot:cell(payvia)="data">
                    <div style="width:5rem;"><span :class="data.item.payvia == 'stripe' ? 'text-success': 'text-dark'">{{data.item.payvia}} </span> </div>
                  </template>
                  <template v-slot:cell(date)="data">
                    <div style="width:5rem;" class="cursor-pointer">{{$filters.date_ago(data.item.created_at) }} </div>
                  </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded">
                  <b-pagination v-model="paginate.currentPage" :limit="paginate.limit" 
                  :total-rows="searchedItems.length" :per-page="paginate.perPage"></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "payment-list",
  data() {
    return {
      isLoading: false,
      payments:[],
      paginate:{
        currentPage: 1,
        perPage: 10,
        limit:3
      },
      pageOptions: [10, 25, 50, 100],
      searchQuery: '',
      selected: [],
      isSelectAll: false,
      columns: [
          {
              key: "check",
              label: "ID"
          },
          {
            key: "user_email",
            label: "User email",
            sortable: true
          },
          {
            key: "paymentid",
            label: "References",
            sortable: true
          },
          {
            key: "paid_amount",
            label: "Amount",
            sortable: true
          },
          {
            key: "payvia",
            label: "Pay Via",
            sortable: true
          },
          {
            key: "currency",
            label: "Currency",
            sortable: true
          },
          {
            key: "date",
            label: "Date",
            sortable: true
          },
      ]
    }
  },
  computed: {
    searchedItems(){
      return this.payments.filter(item => item.user_email ? item.user_email.toLowerCase().includes(this.searchQuery.toLowerCase()) : false )
    },
    paginatedItems(){
      return this.searchedItems.slice(
        this.paginateStart,
        this.paginateEnd
      );
    },
    paginateStart(){
      return (this.paginate.currentPage - 1) * this.paginate.perPage;
    },
    paginateEnd(){
      return (this.paginate.currentPage - 1) * this.paginate.perPage + this.paginate.perPage;
    },
  },
  methods: {
    selectAllRows() {
      this.isSelectAll = !this.isSelectAll
      if(this.isSelectAll){
        this.selected = this.payments.map(o => o.id );
      }else{
        this.selected = []
      }
    },
    fetchPayments() {
      this.isLoading = true
      this.$http.get(`/payments`)
      .then((response) => {
        this.isLoading = false
        if(response.data.success){
          this.payments = response.data.data.payments;
        }
      })
    },
  },
  created(){
    this.fetchPayments()
  },
}

</script>

